/* error page style  */

/* body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  } */
/*   
  .container {
    text-align: center;
  }
   */
  .error-sec .number {
    font-size: 5rem;
    color: #e44d26;
  }
  
  .error-sec .text {
    font-size: 2rem;
    color: #333;
  }
  .error-sec{
    padding: 100px 0;
  }
  .error-sec .link {
    text-decoration: none;
    color: #e44d26;
    font-weight: bold;
  }
  
  .error-sec .link:hover {
    text-decoration: underline;
  }
  