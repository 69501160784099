.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
    .loader-container {
        top: 40%;
        left: 50%;
    }
}
